import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Calendar = ({ events, currentMonth, setCurrentMonth, onEventClick }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarDays, setCalendarDays] = useState([]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    generateCalendarDays();
  }, [currentMonth, events]);

  const generateCalendarDays = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();

    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    const days = [];

    // Add previous month's days
    for (let i = 0; i < firstDay.getDay(); i++) {
      const date = new Date(year, month, -i);
      days.unshift({
        date,
        isCurrentMonth: false,
        events: getEventsForDate(date),
      });
    }

    // Add current month's days
    for (let i = 1; i <= lastDay.getDate(); i++) {
      const date = new Date(year, month, i);
      days.push({
        date,
        isCurrentMonth: true,
        events: getEventsForDate(date),
      });
    }

    // Add next month's days
    const remainingDays = 42 - days.length;
    for (let i = 1; i <= remainingDays; i++) {
      const date = new Date(year, month + 1, i);
      days.push({
        date,
        isCurrentMonth: false,
        events: getEventsForDate(date),
      });
    }

    setCalendarDays(days);
  };

  const getEventsForDate = (date) => {
    return events.filter((event) => {
      const eventDate = new Date(event.date);
      return eventDate.toDateString() === date.toDateString();
    });
  };

  const navigateMonth = (direction) => {
    setCurrentMonth(
      new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth() + direction,
        1
      )
    );
    setSelectedDate(null);
  };

  const formatTime = (timeString) => {
    if (!timeString) return "";

    // Parse the time string (assuming format like "14:00")
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));

    return date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .replace(/\s+/g, "")
      .toLowerCase(); // Remove space between time and AM/PM
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* Calendar Header */}
      <div className="flex items-center justify-between mb-8">
        <button
          onClick={() => navigateMonth(-1)}
          className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors text-gray-600 dark:text-gray-300"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
          {monthNames[currentMonth.getMonth()]} {currentMonth.getFullYear()}
        </h2>
        <button
          onClick={() => navigateMonth(1)}
          className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors text-gray-600 dark:text-gray-300"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>

      {/* Calendar Grid */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700">
        {/* Day Names */}
        <div className="grid grid-cols-7 gap-px bg-gray-100 dark:bg-gray-700">
          {dayNames.map((day) => (
            <div
              key={day}
              className="bg-white dark:bg-gray-800 py-2 text-center text-sm font-semibold text-gray-700 dark:text-gray-300"
            >
              {day}
            </div>
          ))}
        </div>

        {/* Calendar Days */}
        <div className="grid grid-cols-7 gap-px bg-gray-100 dark:bg-gray-700">
          {calendarDays.map(({ date, isCurrentMonth, events }, index) => (
            <div
              key={index}
              className={`min-h-[120px] cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors
                ${
                  isCurrentMonth
                    ? "bg-white dark:bg-gray-800"
                    : "bg-gray-50 dark:bg-gray-800/50"
                }
                ${
                  selectedDate?.toDateString() === date.toDateString()
                    ? "ring-2 ring-blue-500"
                    : ""
                }`}
              onClick={() => setSelectedDate(date)}
            >
              <div className="p-2">
                <span
                  className={`inline-flex w-6 h-6 items-center justify-center rounded-full text-sm
                    ${
                      isCurrentMonth
                        ? "text-gray-700 dark:text-gray-300"
                        : "text-gray-400 dark:text-gray-500"
                    }
                    ${
                      date.toDateString() === new Date().toDateString()
                        ? "bg-blue-600 text-white"
                        : ""
                    }`}
                >
                  {date.getDate()}
                </span>

                {/* Event Indicators */}
                <div className="mt-2 space-y-1">
                  {events.map((event, eventIndex) => (
                    <div
                      key={eventIndex}
                      onClick={(e) => {
                        e.stopPropagation();
                        onEventClick(event);
                      }}
                      className="block px-2 py-1 text-xs rounded-lg bg-blue-50 dark:bg-blue-900/50 text-blue-600 dark:text-blue-300 truncate hover:bg-blue-100 dark:hover:bg-blue-900/70 transition-colors cursor-pointer"
                    >
                      {event.title}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Selected Date Events */}
      {selectedDate && (
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100">
            Events on {selectedDate.toLocaleDateString()}
          </h3>
          <div className="space-y-4">
            {getEventsForDate(selectedDate).map((event) => (
              <div
                key={event.id}
                onClick={() => onEventClick(event)}
                className="block bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-all p-4 border border-gray-200 dark:border-gray-700 hover:border-blue-500/50 cursor-pointer"
              >
                <div className="flex justify-between items-start mb-2">
                  <span className="px-2 py-1 bg-blue-50 dark:bg-blue-900/50 text-blue-600 dark:text-blue-300 text-sm font-medium rounded-full">
                    {event.category}
                  </span>
                  <time className="text-sm text-gray-500 dark:text-gray-400">
                    {formatTime(event.time)} - {formatTime(event.endTime)}
                  </time>
                </div>
                <h4 className="text-lg font-semibold mb-2 text-gray-900 dark:text-gray-100">
                  {event.title}
                </h4>
                <p className="text-gray-600 dark:text-gray-300">
                  {event.description}
                </p>
              </div>
            ))}
            {getEventsForDate(selectedDate).length === 0 && (
              <p className="text-gray-500 dark:text-gray-400 text-center py-4">
                No events scheduled for this date
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
