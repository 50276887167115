import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { auth } from "../config/firebase";

const Navigation = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAdmin } = useAuth();

  console.log("Navigation - isAdmin:", isAdmin); // Debug log
  console.log("Navigation - user:", user?.email); // Debug log

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Title and Logo */}
          <Link to="/" className="flex items-center gap-3">
            <img
              src={`/images/${
                location.pathname === "/" ? "20k.png" : "greylogo.png"
              }`}
              alt="AZ Boys Gymnastics Logo"
              className="w-12 h-12 object-contain dark:hidden"
            />
            <img
              src="/images/greylogo.png"
              alt="AZ Boys Gymnastics Logo"
              className="w-12 h-12 object-contain hidden dark:block"
            />
            <span className="text-xl font-bold text-gray-900 dark:text-blue-400">
              AZ Boys Gymnastics
            </span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex space-x-8">
            <Link
              to="/"
              className={`${
                isActive("/")
                  ? "text-blue-600 dark:text-blue-400 font-semibold"
                  : "text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              } transition-colors`}
            >
              Home
            </Link>
            <Link
              to="/events"
              className={`${
                isActive("/events")
                  ? "text-blue-600 dark:text-blue-400 font-semibold"
                  : "text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              } transition-colors`}
            >
              Events
            </Link>
            <Link
              to="/updates"
              className={`${
                isActive("/updates")
                  ? "text-blue-600 dark:text-blue-400 font-semibold"
                  : "text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              } transition-colors`}
            >
              Updates
            </Link>
            <Link
              to="/contact"
              className={`${
                isActive("/contact")
                  ? "text-blue-600 dark:text-blue-400 font-semibold"
                  : "text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              } transition-colors`}
            >
              Contact
            </Link>
            {isAdmin && (
              <div className="relative group">
                <button className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors py-2">
                  Admin
                </button>
                <div className="absolute left-0 top-full mt-1 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 transform -translate-y-1 group-hover:translate-y-0 z-50">
                  <div className="py-1">
                    <Link
                      to="/admin/events"
                      className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      Manage Events
                    </Link>
                    <Link
                      to="/admin/updates"
                      className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      Manage Updates
                    </Link>
                    <Link
                      to="/admin/contacts"
                      className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      Contact Requests
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {user ? (
              <button
                onClick={() => auth.signOut()}
                className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors"
              >
                Logout
              </button>
            ) : (
              <Link
                to="/login"
                className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors"
              >
                Login
              </Link>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden p-2 text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden ${isOpen ? "block" : "hidden"}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-800">
          <Link
            to="/"
            onClick={handleLinkClick}
            className={`block px-3 py-2 rounded-md text-base font-medium ${
              isActive("/")
                ? "text-blue-600 dark:text-blue-400"
                : "text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-800"
            } transition-colors`}
          >
            Home
          </Link>
          <Link
            to="/events"
            onClick={handleLinkClick}
            className={`block px-3 py-2 rounded-md text-base font-medium ${
              isActive("/events")
                ? "text-blue-600 dark:text-blue-400"
                : "text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-800"
            } transition-colors`}
          >
            Events
          </Link>
          <Link
            to="/updates"
            onClick={handleLinkClick}
            className={`block px-3 py-2 rounded-md text-base font-medium ${
              isActive("/updates")
                ? "text-blue-600 dark:text-blue-400"
                : "text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-800"
            } transition-colors`}
          >
            Updates
          </Link>
          <Link
            to="/contact"
            onClick={handleLinkClick}
            className={`block px-3 py-2 rounded-md text-base font-medium ${
              isActive("/contact")
                ? "text-blue-600 dark:text-blue-400"
                : "text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-800"
            } transition-colors`}
          >
            Contact
          </Link>
          {isAdmin && isOpen && (
            <>
              <Link
                to="/admin/events"
                onClick={handleLinkClick}
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-800"
              >
                Manage Events
              </Link>
              <Link
                to="/admin/updates"
                onClick={handleLinkClick}
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-800"
              >
                Manage Updates
              </Link>
              <Link
                to="/admin/contacts"
                onClick={handleLinkClick}
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-800"
              >
                Contact Requests
              </Link>
            </>
          )}
          {user ? (
            <button
              onClick={() => {
                auth.signOut();
                handleLinkClick();
              }}
              className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
            >
              Logout
            </button>
          ) : (
            <Link
              to="/login"
              onClick={handleLinkClick}
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
            >
              Login
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
