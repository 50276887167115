import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { db } from "../../config/firebase";
import { doc, getDoc } from "firebase/firestore";

const DEFAULT_HERO_IMAGE = "/images/hero-bbackground.webp";

const formatTimestamp = (timestamp) => {
  if (!timestamp) return "";

  // Convert Firestore timestamp to Date object
  const date = timestamp.toDate();

  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
};

const UpdateDetail = () => {
  const { id } = useParams();
  const [update, setUpdate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUpdate = async () => {
      try {
        const updateDoc = await getDoc(doc(db, "updates", id));
        if (updateDoc.exists()) {
          setUpdate({
            id: updateDoc.id,
            ...updateDoc.data(),
          });
        } else {
          console.error("Update not found");
          // You might want to handle the 404 case here
        }
      } catch (error) {
        console.error("Error fetching update:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUpdate();
  }, [id]);

  const formatContent = (content) => {
    // Split content into paragraphs and preserve line breaks
    return content.split("\n").map(
      (paragraph, index) =>
        paragraph.trim() && (
          <p key={index} className="mb-4">
            {paragraph}
          </p>
        )
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-white dark:bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!update) {
    return (
      <div className="min-h-screen bg-white dark:bg-gray-900 flex items-center justify-center">
        <div className="text-center text-gray-600 dark:text-gray-400">
          Update not found
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
      {/* Hero Section - Always use default image */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative h-[50vh] min-h-[400px]"
      >
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${DEFAULT_HERO_IMAGE})`, // Always use default image
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>

        <div className="relative h-full max-w-7xl mx-auto px-4">
          <div className="flex flex-col justify-end h-full pb-16">
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <span className="inline-block px-4 py-2 bg-blue-600/20 dark:bg-blue-900 text-blue-600 dark:text-blue-200 text-sm font-medium rounded-full mb-4">
                {update.category || "General"}
              </span>
              <h1 className="text-4xl md:text-5xl font-bold mb-4 text-white">
                {update.title}
              </h1>
              <div className="flex items-center space-x-4 text-gray-200 dark:text-gray-300">
                <time>{formatTimestamp(update.createdAt)}</time>
                {update.url && (
                  <>
                    <span>•</span>
                    <a
                      href={update.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:text-blue-300 transition-colors inline-flex items-center"
                    >
                      External Resource
                      <svg
                        className="w-4 h-4 ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                        />
                      </svg>
                    </a>
                  </>
                )}
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* Content Section */}
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
          {/* Main Content */}
          <motion.div
            className="lg:col-span-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            {/* Content with better formatting - Moved to top */}
            <div className="prose dark:prose-invert max-w-none mb-8">
              {formatContent(update.content)}
            </div>

            {/* Update Image - if exists */}
            {update.image && (
              <div className="mb-8">
                <div className="rounded-lg overflow-hidden">
                  <img
                    src={update.image}
                    alt={update.title}
                    className="w-full h-auto object-cover"
                  />
                </div>
              </div>
            )}

            {/* Documents Section - Simplified for download only */}
            {update.documents && update.documents.length > 0 && (
              <div className="mb-8 space-y-4">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                  Documents
                </h3>
                <div className="space-y-3">
                  {update.documents.map((doc, index) => (
                    <div
                      key={index}
                      className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700"
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3">
                          <svg
                            className="w-6 h-6 text-red-500 dark:text-red-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                          </svg>
                          <div>
                            <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                              {doc.name}
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              PDF Document
                            </p>
                          </div>
                        </div>
                        <a
                          href={doc.url}
                          download
                          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                        >
                          <svg
                            className="w-5 h-5 mr-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                          Download PDF
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Image Gallery - Only show if gallery exists */}
            {update.gallery && update.gallery.length > 0 && (
              <div className="mt-12">
                <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-gray-100">
                  Gallery
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {update.gallery.map((image, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.1 }}
                      className="aspect-square relative overflow-hidden rounded-lg"
                    >
                      <img
                        src={image}
                        alt={`Gallery image ${index + 1}`}
                        className="absolute inset-0 w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                        loading="lazy"
                      />
                    </motion.div>
                  ))}
                </div>
              </div>
            )}
          </motion.div>

          {/* Sidebar */}
          <motion.div
            className="lg:col-span-1"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.6 }}
          >
            <div className="bg-gray-50 dark:bg-gray-800 rounded-xl p-6 sticky top-6">
              {/* Update Details */}
              <div className="mb-6 pb-6 border-b border-gray-200 dark:border-gray-700">
                <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">
                  Update Details
                </h3>
                <div className="space-y-3">
                  <div>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Category
                    </span>
                    <p className="font-medium text-gray-900 dark:text-gray-100">
                      {update.category || "General"}
                    </p>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Author
                    </span>
                    <p className="font-medium text-gray-900 dark:text-gray-100">
                      {update.author || "Anonymous"}
                    </p>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Published
                    </span>
                    <p className="font-medium text-gray-900 dark:text-gray-100">
                      {formatTimestamp(update.createdAt)}
                    </p>
                  </div>
                  {update.url && (
                    <div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        External Link
                      </span>
                      <a
                        href={update.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block font-medium text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 truncate"
                      >
                        {update.url}
                      </a>
                    </div>
                  )}
                </div>
              </div>

              {/* Related Updates - Only show if they exist */}
              {update.relatedUpdates && update.relatedUpdates.length > 0 && (
                <div className="bg-gray-50 dark:bg-gray-800 rounded-xl">
                  <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">
                    Related Updates
                  </h3>
                  {update.relatedUpdates.map((related, index) => (
                    <div
                      key={index}
                      className="mb-4 pb-4 border-b border-gray-200 dark:border-gray-700 last:border-0 last:mb-0 last:pb-0"
                    >
                      <h4 className="font-semibold mb-2 text-gray-900 dark:text-gray-100">
                        {related.title}
                      </h4>
                      <time className="text-sm text-gray-500 dark:text-gray-400">
                        {related.date}
                      </time>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default UpdateDetail;
