import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { db } from "../../config/firebase";
import { doc, getDoc } from "firebase/firestore";

const EventDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showShareModal, setShowShareModal] = useState(false);
  const [mapError, setMapError] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventRef = doc(db, "events", id);
        const eventDoc = await getDoc(eventRef);

        if (eventDoc.exists()) {
          const eventData = { id: eventDoc.id, ...eventDoc.data() };
          setEvent(eventData);
        }
      } catch (error) {
        console.error("Error fetching event:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id]);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: "0.75rem",
  };

  const mapOptions = {
    disableDefaultUI: false,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: true,
    streetViewControl: true,
    rotateControl: true,
    fullscreenControl: true,
    styles: [
      {
        featureType: "all",
        elementType: "geometry",
        stylers: [{ visibility: "on" }],
      },
    ],
  };

  const handleMapLoad = useCallback(
    (map) => {
      setMapLoaded(true);
      setMapError(false);

      // Use the Geocoder to center the map on the address
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: event.location }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          map.setCenter(location);
          // Create a marker at the location
          new window.google.maps.Marker({
            map,
            position: location,
          });
        } else {
          console.error("Geocode was not successful:", status);
          setMapError(true);
        }
      });
    },
    [event?.location]
  );

  const handleMapError = useCallback(() => {
    console.error("Error loading map");
    setMapError(true);
  }, []);

  // Share options
  const shareOptions = [
    {
      name: "Copy Link",
      icon: "🔗",
      action: () => {
        navigator.clipboard.writeText(window.location.href);
        alert("Link copied to clipboard!");
        setShowShareModal(false);
      },
    },
    {
      name: "Facebook",
      icon: "📘",
      action: () => {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            window.location.href
          )}`
        );
      },
    },
    {
      name: "Twitter",
      icon: "🐦",
      action: () => {
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            window.location.href
          )}&text=${encodeURIComponent(event.title)}`
        );
      },
    },
    {
      name: "Email",
      icon: "✉️",
      action: () => {
        window.location.href = `mailto:?subject=${encodeURIComponent(
          event.title
        )}&body=${encodeURIComponent(
          `Check out this event: ${window.location.href}`
        )}`;
      },
    },
  ];

  const formatTime = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":");
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minutes} ${ampm}`;
  };

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900">Loading...</h2>
        </div>
      </div>
    );
  }

  if (!event) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900">Event not found</h2>
          <p className="mt-2 text-gray-600">
            The event you're looking for doesn't exist.
          </p>
          <button
            onClick={() => navigate("/events")}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Back to Events
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Back Button */}
        <button
          onClick={() => navigate("/events")}
          className="flex items-center text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 mb-8 transition-colors"
        >
          <svg
            className="w-5 h-5 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          Back to Events
        </button>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
          {/* Event Header - Background overlay adjusted for dark mode */}
          <div
            className="relative px-8 py-12 text-white"
            style={{
              backgroundImage: `
                linear-gradient(
                  to right,
                  rgba(0, 0, 0, 0.6),
                  rgba(0, 0, 0, 0.5)
                ),
                url('/images/3f89229905994ef5b18964fd2ddf509e.jpg.webp')
              `,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="relative max-w-3xl z-10">
              <span
                className={`inline-block px-3 py-1 rounded-full text-sm font-semibold shadow-sm
                ${
                  event.category === "Competition"
                    ? "bg-red-500"
                    : event.category === "Training"
                    ? "bg-blue-500"
                    : event.category === "Workshop"
                    ? "bg-green-500"
                    : "bg-purple-500"
                } bg-opacity-90`}
              >
                {event.category}
              </span>
              <h1 className="text-4xl font-bold mt-4 text-white drop-shadow-md">
                {event.title}
              </h1>
              <p className="mt-4 text-xl text-white/90 drop-shadow-sm">
                {event.description}
              </p>
            </div>
          </div>

          {/* Event Details */}
          <div className="p-8">
            <div className="grid md:grid-cols-2 gap-12">
              {/* Left Column */}
              <div className="space-y-8">
                <div>
                  <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
                    Event Details
                  </h2>
                  <div className="space-y-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 w-6 text-gray-500 dark:text-gray-400">
                        📅
                      </div>
                      <div className="ml-4">
                        <p className="font-medium text-gray-900 dark:text-gray-100">
                          Date & Time
                        </p>
                        <div className="flex items-center text-gray-600 dark:text-gray-300 mb-2">
                          <svg
                            className="w-5 h-5 mr-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                            />
                          </svg>
                          {event.dateDisplay ||
                            (event.endDate
                              ? `${new Date(
                                  event.date
                                ).toLocaleDateString()} - ${new Date(
                                  event.endDate
                                ).toLocaleDateString()}`
                              : new Date(event.date).toLocaleDateString())}
                        </div>
                        <p className="text-gray-600 dark:text-gray-300">
                          {formatTime(event.time)} - {formatTime(event.endTime)}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-start">
                      <div className="flex-shrink-0 w-6 text-gray-500 dark:text-gray-400">
                        📍
                      </div>
                      <div className="ml-4">
                        <p className="font-medium text-gray-900 dark:text-gray-100">
                          Location
                        </p>
                        <p className="text-gray-600 dark:text-gray-300">
                          {event.location}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-start">
                      <div className="flex-shrink-0 w-6 text-gray-500 dark:text-gray-400">
                        💰
                      </div>
                      <div className="ml-4">
                        <p className="font-medium text-gray-900 dark:text-gray-100">
                          Registration Fee
                        </p>
                        <p className="text-gray-600 dark:text-gray-300">
                          {event.price}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
                    Requirements
                  </h2>
                  <p className="text-gray-600 dark:text-gray-300">
                    Level Required: {event.levelRequired}
                  </p>
                </div>

                {/* Share Button */}
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => setShowShareModal(true)}
                    className="flex items-center px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                  >
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                      />
                    </svg>
                    Share Event
                  </button>
                </div>
              </div>

              {/* Right Column */}
              <div className="space-y-8">
                {/* Registration Section */}
                <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
                  <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
                    Registration
                  </h2>
                  <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-300">
                      Registration Deadline:{" "}
                      {new Date(
                        event.registrationDeadline
                      ).toLocaleDateString()}
                    </p>
                    <a
                      href={`mailto:${
                        event.contactEmail
                      }?subject=${encodeURIComponent(
                        `Registration for ${event.title}`
                      )}&body=${encodeURIComponent(
                        `Hello,\n\nI would like to register for the event: ${event.title}\n\nBest regards`
                      )}`}
                      className="block w-full bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors text-center"
                    >
                      Register Now
                    </a>
                  </div>
                </div>

                <div>
                  <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
                    Contact Information
                  </h2>
                  <div className="space-y-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 w-6 text-gray-500 dark:text-gray-400">
                        👤
                      </div>
                      <div className="ml-4">
                        <p className="font-medium text-gray-900 dark:text-gray-100">
                          Event Coordinator
                        </p>
                        <p className="text-gray-600 dark:text-gray-300">
                          {event.coordinator}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex-shrink-0 w-6 text-gray-500 dark:text-gray-400">
                        ✉️
                      </div>
                      <div className="ml-4">
                        <p className="font-medium text-gray-900 dark:text-gray-100">
                          Email
                        </p>
                        <a
                          href={`mailto:${event.contactEmail}`}
                          className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 transition-colors"
                        >
                          {event.contactEmail}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Grid for Map and Photo */}
                <div className="mt-8">
                  <div className="grid md:grid-cols-2 gap-8">
                    {/* Location Section */}
                    <div>
                      <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
                        Location
                      </h2>
                      {event.location ? (
                        <>
                          <div className="relative h-[400px] rounded-lg overflow-hidden">
                            <GoogleMap
                              mapContainerStyle={mapContainerStyle}
                              center={{ lat: 33.4484, lng: -112.074 }}
                              zoom={15}
                              options={mapOptions}
                              onLoad={handleMapLoad}
                            />
                          </div>
                          {/* Get Directions Link */}
                          <div className="mt-4">
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                event.location
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 flex items-center transition-colors"
                            >
                              <svg
                                className="w-5 h-5 mr-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                              Get Directions
                            </a>
                          </div>
                        </>
                      ) : (
                        <div className="bg-gray-100 dark:bg-gray-700 rounded-lg p-4 text-center">
                          <p className="text-gray-600 dark:text-gray-300">
                            No location provided
                          </p>
                        </div>
                      )}
                    </div>

                    {/* Event Photo Section */}
                    {event.image && (
                      <div>
                        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
                          Event Photo
                        </h2>
                        <div
                          className="rounded-lg overflow-hidden shadow-lg cursor-pointer"
                          onClick={() => setShowImageModal(true)}
                        >
                          <img
                            src={event.image}
                            alt={event.title}
                            className="w-full h-auto object-contain hover:opacity-90 transition-opacity"
                            style={{ maxHeight: "400px" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Share Modal */}
        {showShareModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg max-w-md w-full mx-4 p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">
                  Share Event
                </h3>
                <button
                  onClick={() => setShowShareModal(false)}
                  className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full text-gray-600 dark:text-gray-300"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {shareOptions.map((option) => (
                  <button
                    key={option.name}
                    onClick={option.action}
                    className="flex items-center justify-center p-4 bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
                  >
                    <span className="mr-2">{option.icon}</span>
                    {option.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Full-size Image Modal */}
        {showImageModal && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 z-50 overflow-y-auto"
            onClick={() => setShowImageModal(false)}
          >
            <div className="min-h-screen px-4 py-6 flex items-center justify-center">
              <div className="relative bg-white dark:bg-gray-800 rounded-lg max-w-7xl w-full overflow-hidden">
                {/* Close Button */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowImageModal(false);
                  }}
                  className="absolute top-4 right-4 bg-white dark:bg-gray-800 text-gray-800 dark:text-white p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors shadow-lg z-10"
                  aria-label="Close modal"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                {/* Image Container */}
                <div className="overflow-auto max-h-[90vh]">
                  <img
                    src={event.image}
                    alt={event.title}
                    className="w-full h-auto object-contain"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Documents Section - Now below the photo */}
        {event.documents && event.documents.length > 0 && (
          <div className="mt-8 space-y-6">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
              Event Documents
            </h2>
            <div className="space-y-4">
              {event.documents.map((doc, index) => (
                <div
                  key={index}
                  className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <svg
                        className="w-6 h-6 text-red-500 dark:text-red-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                      </svg>
                      <div>
                        <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                          {doc.name}
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          PDF Document
                        </p>
                      </div>
                    </div>
                    <a
                      href={doc.url}
                      download
                      className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                    >
                      <svg
                        className="w-5 h-5 mr-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                        />
                      </svg>
                      Download PDF
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventDetail;
