import React, { useState } from "react";
import seedDatabase from "../../utils/seedDatabase";

const DatabaseSeeder = () => {
  const [seeding, setSeeding] = useState(false);
  const [message, setMessage] = useState("");

  const handleSeed = async () => {
    if (
      window.confirm(
        "Are you sure you want to seed the database? This will add all events from the data file."
      )
    ) {
      setSeeding(true);
      setMessage("Seeding database...");
      try {
        await seedDatabase();
        setMessage("Database seeded successfully!");
      } catch (error) {
        setMessage("Error seeding database: " + error.message);
      } finally {
        setSeeding(false);
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-gray-100">
        Database Seeder
      </h1>

      <button
        onClick={handleSeed}
        disabled={seeding}
        className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
      >
        {seeding ? "Seeding..." : "Seed Database"}
      </button>

      {message && (
        <p className="mt-4 text-gray-700 dark:text-gray-300">{message}</p>
      )}
    </div>
  );
};

export default DatabaseSeeder;
