import { db, storage } from "../config/firebase";
import { collection, setDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import events from "../data/events";

const uploadEventImage = async (imageUrl, eventId) => {
  try {
    // For demo purposes, we'll use fetch to get the image from public folder
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const imageName = imageUrl.split("/").pop();
    const storageRef = ref(storage, `events/${eventId}/${imageName}`);

    await uploadBytes(storageRef, blob);
    return await getDownloadURL(storageRef);
  } catch (error) {
    console.error(`Error uploading image for event ${eventId}:`, error);
    return null;
  }
};

const uploadEventDocuments = async (documents, eventId) => {
  const updatedDocs = [];

  for (const doc of documents || []) {
    try {
      // For demo purposes, assuming PDFs are in the public folder
      const response = await fetch(`/pdfs/${doc.storageRef}`);
      const blob = await response.blob();

      const storageRef = ref(storage, doc.storageRef);
      await uploadBytes(storageRef, blob);
      const url = await getDownloadURL(storageRef);

      updatedDocs.push({
        ...doc,
        url,
      });
    } catch (error) {
      console.error(`Error uploading document for event ${eventId}:`, error);
    }
  }

  return updatedDocs;
};

const seedDatabase = async () => {
  try {
    for (const event of events) {
      const eventRef = doc(collection(db, "events"));
      const eventId = eventRef.id;

      // Upload image if exists
      let imageUrl = event.image;
      if (imageUrl && imageUrl.startsWith("/")) {
        imageUrl = await uploadEventImage(imageUrl, eventId);
      }

      // Upload documents if exist
      const updatedDocs = await uploadEventDocuments(event.documents, eventId);

      // Prepare event data
      const eventData = {
        ...event,
        id: eventId,
        image: imageUrl,
        documents: updatedDocs,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      // Save to Firestore
      await setDoc(eventRef, eventData);
      console.log(`Event ${eventId} uploaded successfully`);
    }

    console.log("Database seeding completed successfully");
  } catch (error) {
    console.error("Error seeding database:", error);
  }
};

export default seedDatabase;
