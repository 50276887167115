import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, storage } from "../../config/firebase";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const EventEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState({
    title: "",
    description: "",
    date: "",
    endDate: "",
    time: "",
    endTime: "",
    location: "",
    category: "",
    registrationDeadline: "",
    price: "",
    levelRequired: "",
    coordinator: "",
    contactEmail: "",
    contactPhone: "",
    image: "",
    documents: [],
    additionalDetails: {
      venue: "",
      spectatorInfo: "",
      paymentDeadline: "",
      latePaymentFee: "",
    },
  });
  const [uploadingDoc, setUploadingDoc] = useState(false);
  const [uploadError, setUploadError] = useState("");

  useEffect(() => {
    if (id !== "new") {
      const fetchEvent = async () => {
        const eventDoc = await getDoc(doc(db, "events", id));
        if (eventDoc.exists()) {
          setEvent(eventDoc.data());
        }
        setLoading(false);
      };
      fetchEvent();
    } else {
      setLoading(false);
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const eventId = id === "new" ? doc(collection(db, "events")).id : id;
      await setDoc(doc(db, "events", eventId), event);
      navigate("/admin/events");
    } catch (error) {
      console.error("Error saving event:", error);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setUploadError("");

    if (file) {
      // Validate file type
      if (!file.type.match("image.*")) {
        setUploadError("Only image files are allowed");
        return;
      }

      // Validate file size (2MB limit)
      if (file.size > 2 * 1024 * 1024) {
        setUploadError("Image size must be less than 2MB");
        return;
      }

      try {
        // Create proper storage path
        const eventId = id === "new" ? doc(collection(db, "events")).id : id;
        const safeFileName = file.name.replace(/[^a-zA-Z0-9.-]/g, "_");
        const storagePath = `events/${eventId}/${safeFileName}`;

        const storageRef = ref(storage, storagePath);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);

        setEvent({ ...event, image: url });
      } catch (error) {
        console.error("Error uploading image:", error);
        setUploadError("Failed to upload image. Please try again.");
      }
    }
  };

  const handleDocumentUpload = async (e) => {
    const file = e.target.files[0];
    setUploadError("");

    // Validate file type and size
    if (file) {
      // Check if it's a PDF
      if (file.type !== "application/pdf") {
        setUploadError("Only PDF files are allowed");
        return;
      }

      // Check file size (5MB limit)
      if (file.size > 5 * 1024 * 1024) {
        setUploadError("File size must be less than 5MB");
        return;
      }

      setUploadingDoc(true);
      try {
        // Create proper storage path
        const eventId = id === "new" ? doc(collection(db, "events")).id : id;
        const safeFileName = file.name.replace(/[^a-zA-Z0-9.-]/g, "_"); // Sanitize filename
        const storagePath = `events/${eventId}/${safeFileName}`;

        const storageRef = ref(storage, storagePath);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);

        const newDocument = {
          name: file.name,
          type: "pdf",
          storageRef: storagePath,
          url: url,
        };

        setEvent({
          ...event,
          documents: [...(event.documents || []), newDocument],
        });
      } catch (error) {
        console.error("Error uploading document:", error);
        setUploadError("Failed to upload document. Please try again.");
      } finally {
        setUploadingDoc(false);
      }
    }
  };

  const handleRemoveDocument = (index) => {
    const newDocuments = [...event.documents];
    newDocuments.splice(index, 1);
    setEvent({ ...event, documents: newDocuments });
  };

  if (loading) {
    return <div className="text-center py-12">Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-gray-100">
        {id === "new" ? "Create New Event" : "Edit Event"}
      </h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Title */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Title
            </label>
            <input
              type="text"
              value={event.title}
              onChange={(e) => setEvent({ ...event, title: e.target.value })}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              required
            />
          </div>

          {/* Category */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Category
            </label>
            <select
              value={event.category}
              onChange={(e) => setEvent({ ...event, category: e.target.value })}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              required
            >
              <option value="">Select Category</option>
              <option value="Competition">Competition</option>
              <option value="Training">Training</option>
              <option value="Camp">Camp</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Date */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Start Date
            </label>
            <input
              type="date"
              value={event.date}
              onChange={(e) => setEvent({ ...event, date: e.target.value })}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              required
            />
          </div>

          {/* End Date */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              End Date
            </label>
            <input
              type="date"
              value={event.endDate}
              onChange={(e) => setEvent({ ...event, endDate: e.target.value })}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            />
          </div>

          {/* Time */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Start Time
            </label>
            <input
              type="time"
              value={event.time}
              onChange={(e) => setEvent({ ...event, time: e.target.value })}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              required
            />
          </div>

          {/* End Time */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              End Time
            </label>
            <input
              type="time"
              value={event.endTime}
              onChange={(e) => setEvent({ ...event, endTime: e.target.value })}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              required
            />
          </div>

          {/* Location */}
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Location
            </label>
            <input
              type="text"
              value={event.location}
              onChange={(e) => setEvent({ ...event, location: e.target.value })}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              required
            />
          </div>

          {/* Description */}
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Description
            </label>
            <textarea
              value={event.description}
              onChange={(e) =>
                setEvent({ ...event, description: e.target.value })
              }
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              rows="4"
              required
            />
          </div>

          {/* Image Upload */}
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Event Image (Max 2MB)
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            />
            {uploadError && (
              <p className="text-sm text-red-500 mt-2">{uploadError}</p>
            )}
            <p className="text-sm text-gray-500 mt-1">
              Supported formats: JPG, PNG, GIF (max 2MB)
            </p>
            {event.image && (
              <img
                src={event.image}
                alt="Event preview"
                className="mt-4 max-h-48 rounded-lg"
              />
            )}
          </div>
        </div>

        <div className="mt-8">
          <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">
            Event Documents
          </h3>

          {/* Document Upload */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Upload PDF Document (Max 5MB)
            </label>
            <input
              type="file"
              accept=".pdf,application/pdf"
              onChange={handleDocumentUpload}
              disabled={uploadingDoc}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            />
            {uploadingDoc && (
              <p className="text-sm text-blue-500 mt-2">
                Uploading document...
              </p>
            )}
            {uploadError && (
              <p className="text-sm text-red-500 mt-2">{uploadError}</p>
            )}
            <p className="text-sm text-gray-500 mt-1">
              Only PDF files are allowed, maximum size 5MB
            </p>
          </div>

          {/* Document List */}
          <div className="space-y-4">
            {event.documents?.map((doc, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-800 rounded-lg"
              >
                <div className="flex items-center">
                  <svg
                    className="w-8 h-8 text-red-500 mr-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
                    <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
                  </svg>
                  <span className="text-gray-700 dark:text-gray-300">
                    {doc.name}
                  </span>
                </div>
                <button
                  type="button"
                  onClick={() => handleRemoveDocument(index)}
                  className="text-red-500 hover:text-red-700 transition-colors"
                >
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Requirements Section */}
        <div className="border-t border-gray-200 dark:border-gray-700 mt-8 pt-8">
          <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
            Requirements & Registration
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Level Required
              </label>
              <input
                type="text"
                value={event.levelRequired}
                onChange={(e) =>
                  setEvent({ ...event, levelRequired: e.target.value })
                }
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                placeholder="e.g., Levels 3-10"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Registration Deadline
              </label>
              <input
                type="date"
                value={event.registrationDeadline}
                onChange={(e) =>
                  setEvent({ ...event, registrationDeadline: e.target.value })
                }
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              />
            </div>

            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Registration Fee
              </label>
              <textarea
                value={event.price}
                onChange={(e) => setEvent({ ...event, price: e.target.value })}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                placeholder="e.g., Level 3: $105/athlete, Level 4-6: $130/athlete"
                rows="2"
              />
            </div>
          </div>
        </div>

        {/* Contact Information Section */}
        <div className="border-t border-gray-200 dark:border-gray-700 mt-8 pt-8">
          <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100">
            Contact Information
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Event Coordinator
              </label>
              <input
                type="text"
                value={event.coordinator}
                onChange={(e) =>
                  setEvent({ ...event, coordinator: e.target.value })
                }
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Contact Email
              </label>
              <input
                type="email"
                value={event.contactEmail}
                onChange={(e) =>
                  setEvent({ ...event, contactEmail: e.target.value })
                }
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Contact Phone (Optional)
              </label>
              <input
                type="tel"
                value={event.contactPhone}
                onChange={(e) =>
                  setEvent({ ...event, contactPhone: e.target.value })
                }
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              />
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full mt-8 bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
        >
          {id === "new" ? "Create Event" : "Update Event"}
        </button>
      </form>
    </div>
  );
};

export default EventEditor;
