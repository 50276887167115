import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Events from "./pages/Events/index";
import Updates from "./pages/Updates/index";
import EventDetail from "./pages/Events/EventDetail";
import UpdateDetail from "./pages/Updates/UpdateDetail";
import Programs from "./pages/Programs/index";
import Contact from "./pages/Contact";
import { ThemeProvider } from "./contexts/ThemeContext";
import ThemeToggle from "./components/ThemeToggle";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import EventsManager from "./pages/Admin/EventsManager";
import EventEditor from "./pages/Admin/EventEditor";
import Login from "./pages/Login";
import DatabaseSeeder from "./pages/Admin/DatabaseSeeder";
import UpdatesManager from "./pages/Admin/UpdatesManager";
import UpdateEditor from "./pages/Admin/UpdateEditor";
import Chatbot from "./components/Chatbot/index";
import ContactManager from "./pages/Admin/ContactManager";
import TermsAndConditions from "./pages/Legal/TermsAndConditions";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";

const libraries = ["places", "marker"];

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          libraries={libraries}
        >
          <Router>
            <div className="flex flex-col min-h-screen bg-gray-950 overflow-x-hidden w-full">
              <Navigation />
              <main className="flex-grow w-full relative">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/events" element={<Events />} />
                  <Route path="/events/:id" element={<EventDetail />} />
                  <Route path="/updates" element={<Updates />} />
                  <Route path="/updates/:id" element={<UpdateDetail />} />
                  <Route path="/programs" element={<Programs />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route
                    path="/admin/events"
                    element={
                      <ProtectedRoute>
                        <EventsManager />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/events/:id"
                    element={
                      <ProtectedRoute>
                        <EventEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/admin/seed"
                    element={
                      <ProtectedRoute>
                        <DatabaseSeeder />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/updates"
                    element={
                      <ProtectedRoute>
                        <UpdatesManager />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/updates/:id"
                    element={
                      <ProtectedRoute>
                        <UpdateEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/contacts"
                    element={
                      <ProtectedRoute>
                        <ContactManager />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/terms" element={<TermsAndConditions />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                </Routes>
              </main>
              <Footer />
              <div className="fixed bottom-4 right-4 z-[100] flex flex-col gap-4">
                <Chatbot />
                <ThemeToggle />
              </div>
            </div>
          </Router>
        </LoadScript>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
