import React from "react";

const Programs = () => {
  return (
    <div className="min-h-screen bg-gray-900 py-12">
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-3xl font-bold text-white">Programs</h1>
      </div>
    </div>
  );
};

export default Programs;
