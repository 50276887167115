import React, { createContext, useState, useContext, useEffect } from "react";
import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

// Array of admin emails
const ADMIN_EMAILS = [
  "new@gmail.com",

  // Add more admin emails as needed
];

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      console.log("Current user:", user?.email);
      console.log("Is admin?:", ADMIN_EMAILS.includes(user?.email));
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    user,
    isAdmin: user?.email ? ADMIN_EMAILS.includes(user.email) : false,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
