import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="relative mb-16 w-full aspect-[21/9] overflow-hidden">
        <div
          className="absolute inset-0 w-full h-full"
          style={{
            backgroundImage: `url('/images/legal.webp')`,
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>

      <div className="max-w-4xl mx-auto px-4 pb-16">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          Privacy Policy
        </h1>

        <div className="prose dark:prose-invert max-w-none">
          <h2>1. Information We Collect</h2>
          <p>
            We collect information that you provide directly to us, including:
          </p>
          <ul>
            <li>Name and contact information</li>
            <li>Event registration details</li>
            <li>Communication preferences</li>
            <li>Feedback and correspondence</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Process event registrations</li>
            <li>Send updates and notifications</li>
            <li>Respond to your inquiries</li>
            <li>Improve our services</li>
          </ul>

          <h2>3. Information Sharing</h2>
          <p>
            We do not sell, trade, or otherwise transfer your personally
            identifiable information to third parties. This does not include
            trusted third parties who assist us in operating our website or
            servicing you.
          </p>

          <h2>4. Data Security</h2>
          <p>
            We implement appropriate security measures to protect your personal
            information. However, no method of transmission over the Internet is
            100% secure.
          </p>

          <h2>5. Cookies</h2>
          <p>
            We use cookies to enhance your experience on our website. You can
            choose to disable cookies through your browser settings.
          </p>

          <h2>6. Children's Privacy</h2>
          <p>
            We do not knowingly collect personal information from children under
            13. If you believe we have collected such information, please
            contact us immediately.
          </p>

          <h2>7. Changes to Privacy Policy</h2>
          <p>
            We may update this privacy policy from time to time. We will notify
            you of any changes by posting the new policy on this page.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            If you have questions about this Privacy Policy, please contact us
            through our provided contact methods.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
