import React, { useState, useEffect } from "react";
import { db } from "../../config/firebase";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";

const ContactManager = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const contactsCollection = collection(db, "contacts");
        const contactsSnapshot = await getDocs(contactsCollection);
        const contactsList = contactsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setContacts(contactsList);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  const handleDelete = async (id) => {
    if (
      window.confirm("Are you sure you want to delete this contact request?")
    ) {
      try {
        await deleteDoc(doc(db, "contacts", id));
        setContacts(contacts.filter((contact) => contact.id !== id));
      } catch (error) {
        console.error("Error deleting contact:", error);
      }
    }
  };

  const handleStatusUpdate = async (id, newStatus) => {
    try {
      await updateDoc(doc(db, "contacts", id), {
        status: newStatus,
      });
      setContacts(
        contacts.map((contact) =>
          contact.id === id ? { ...contact, status: newStatus } : contact
        )
      );
    } catch (error) {
      console.error("Error updating contact status:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-12">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-8">
        Contact Requests
      </h1>

      {/* Desktop View */}
      <div className="hidden lg:block overflow-x-auto">
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Subject
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {contacts.map((contact) => (
                <tr key={contact.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {contact.createdAt?.toDate().toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                    {contact.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                    <a
                      href={`mailto:${contact.email}`}
                      className="text-blue-600 dark:text-blue-400 hover:underline"
                    >
                      {contact.email}
                    </a>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                    {contact.subject}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <select
                      value={contact.status}
                      onChange={(e) =>
                        handleStatusUpdate(contact.id, e.target.value)
                      }
                      className="text-sm rounded-full px-3 py-1"
                      style={{
                        backgroundColor:
                          contact.status === "new"
                            ? "#EFF6FF"
                            : contact.status === "in-progress"
                            ? "#FEF3C7"
                            : "#ECFDF5",
                        color:
                          contact.status === "new"
                            ? "#2563EB"
                            : contact.status === "in-progress"
                            ? "#D97706"
                            : "#059669",
                      }}
                    >
                      <option value="new">New</option>
                      <option value="in-progress">In Progress</option>
                      <option value="completed">Completed</option>
                    </select>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => handleDelete(contact.id)}
                      className="text-red-600 hover:text-red-900 dark:hover:text-red-400"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Mobile View */}
      <div className="lg:hidden space-y-4">
        {contacts.map((contact) => (
          <div
            key={contact.id}
            className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4"
          >
            <div className="space-y-4">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-gray-100">
                    {contact.name}
                  </h3>
                  <a
                    href={`mailto:${contact.email}`}
                    className="text-blue-600 dark:text-blue-400 text-sm hover:underline"
                  >
                    {contact.email}
                  </a>
                </div>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {contact.createdAt?.toDate().toLocaleDateString()}
                </span>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Subject
                </p>
                <p className="text-gray-900 dark:text-gray-100">
                  {contact.subject}
                </p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Message
                </p>
                <p className="text-gray-900 dark:text-gray-100">
                  {contact.message}
                </p>
              </div>
              <div className="flex justify-between items-center pt-4 border-t border-gray-200 dark:border-gray-700">
                <select
                  value={contact.status}
                  onChange={(e) =>
                    handleStatusUpdate(contact.id, e.target.value)
                  }
                  className="text-sm rounded-full px-3 py-1"
                  style={{
                    backgroundColor:
                      contact.status === "new"
                        ? "#EFF6FF"
                        : contact.status === "in-progress"
                        ? "#FEF3C7"
                        : "#ECFDF5",
                    color:
                      contact.status === "new"
                        ? "#2563EB"
                        : contact.status === "in-progress"
                        ? "#D97706"
                        : "#059669",
                  }}
                >
                  <option value="new">New</option>
                  <option value="in-progress">In Progress</option>
                  <option value="completed">Completed</option>
                </select>
                <button
                  onClick={() => handleDelete(contact.id)}
                  className="text-red-600 hover:text-red-900 dark:hover:text-red-400"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactManager;
