import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, storage } from "../../config/firebase";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const UpdateEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [update, setUpdate] = useState({
    title: "",
    content: "",
    date: new Date().toISOString().split("T")[0],
    category: "",
    priority: "normal",
    image: "",
    author: "",
    status: "published",
    featured: false,
    url: "",
    documents: [],
  });

  useEffect(() => {
    if (id !== "new") {
      const fetchUpdate = async () => {
        try {
          const updateDoc = await getDoc(doc(db, "updates", id));
          if (updateDoc.exists()) {
            const data = updateDoc.data();
            setUpdate({
              ...data,
              documents: data.documents || [],
            });
          }
        } catch (error) {
          console.error("Error fetching update:", error);
          alert("Error loading update");
        } finally {
          setLoading(false);
        }
      };
      fetchUpdate();
    } else {
      setLoading(false);
    }
  }, [id]);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const isImage = file.type.startsWith("image/");
    const isPDF = file.type === "application/pdf";

    if (!isImage && !isPDF) {
      alert("Only images and PDF files are allowed");
      return;
    }

    const maxSizeImage = 5 * 1024 * 1024; // 5MB
    const maxSizePDF = 10 * 1024 * 1024; // 10MB

    if (isImage && file.size > maxSizeImage) {
      alert("Image files must be less than 5MB");
      return;
    }

    if (isPDF && file.size > maxSizePDF) {
      alert("PDF files must be less than 10MB");
      return;
    }

    try {
      setUploadingFile(true);

      const fileType = isImage ? "images" : "documents";
      const storageRef = ref(
        storage,
        `updates/${fileType}/${Date.now()}-${file.name}`
      );

      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);

      if (isImage) {
        setUpdate({ ...update, image: fileUrl });
      } else {
        setUpdate({
          ...update,
          documents: [
            ...(update.documents || []),
            {
              name: file.name,
              url: fileUrl,
              type: file.type,
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error uploading file:", error);

      if (error.code === "storage/unauthorized") {
        alert("Error: You do not have permission to upload files");
      } else if (error.code === "storage/canceled") {
        alert("Error: Upload was cancelled");
      } else if (error.code === "storage/retry-limit-exceeded") {
        alert("Error: Upload failed due to network issues. Please try again");
      } else {
        alert(`Error uploading file: ${error.message}`);
      }
    } finally {
      setUploadingFile(false);
      e.target.value = "";
    }
  };

  const handleDeleteDocument = (indexToDelete) => {
    setUpdate({
      ...update,
      documents: update.documents.filter((_, index) => index !== indexToDelete),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateId = id === "new" ? doc(collection(db, "updates")).id : id;
      await setDoc(doc(db, "updates", updateId), update);
      navigate("/admin/updates");
    } catch (error) {
      console.error("Error saving update:", error);
      alert("Failed to save update");
    }
  };

  if (loading) {
    return <div className="text-center py-12">Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-gray-100">
        {id === "new" ? "Create New Update" : "Edit Update"}
      </h1>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Title */}
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Title
            </label>
            <input
              type="text"
              value={update.title}
              onChange={(e) => setUpdate({ ...update, title: e.target.value })}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              required
            />
          </div>

          {/* URL Link */}
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              URL Link (Optional)
            </label>
            <input
              type="url"
              value={update.url}
              onChange={(e) => setUpdate({ ...update, url: e.target.value })}
              placeholder="https://example.com"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            />
          </div>

          {/* File Upload Section */}
          <div className="md:col-span-2 space-y-6">
            {/* Image Upload */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Image
              </label>
              <div className="flex items-center space-x-4">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileUpload}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                  disabled={uploadingFile}
                />
              </div>
              {update.image && (
                <div className="mt-2 flex items-start space-x-4">
                  <img
                    src={update.image}
                    alt="Preview"
                    className="h-32 w-auto object-cover rounded"
                  />
                  <button
                    type="button"
                    onClick={() => setUpdate({ ...update, image: "" })}
                    className="px-3 py-1 text-sm text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 border border-red-600 dark:border-red-400 rounded-md hover:bg-red-50 dark:hover:bg-red-900/20 transition-colors"
                  >
                    Remove Image
                  </button>
                </div>
              )}
            </div>

            {/* PDF Upload */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Documents (PDF)
              </label>
              <div className="flex items-center space-x-4">
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileUpload}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                  disabled={uploadingFile}
                />
              </div>

              {/* Document List */}
              {update.documents && update.documents.length > 0 && (
                <div className="mt-4 space-y-2">
                  {update.documents.map((doc, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-800 rounded-lg"
                    >
                      <div className="flex items-center space-x-3">
                        <svg
                          className="w-5 h-5 text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                        </svg>
                        <span className="text-sm text-gray-700 dark:text-gray-300">
                          {doc.name}
                        </span>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleDeleteDocument(index)}
                        className="text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                      >
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Category */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Category
            </label>
            <input
              type="text"
              value={update.category}
              onChange={(e) =>
                setUpdate({ ...update, category: e.target.value })
              }
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            />
          </div>

          {/* Author */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Author
            </label>
            <input
              type="text"
              value={update.author}
              onChange={(e) => setUpdate({ ...update, author: e.target.value })}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            />
          </div>

          {/* Content */}
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Content
            </label>
            <textarea
              value={update.content}
              onChange={(e) =>
                setUpdate({ ...update, content: e.target.value })
              }
              rows="6"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
              required
            />
          </div>

          {/* Featured Toggle */}
          <div className="md:col-span-2">
            <label className="flex items-center space-x-3">
              <input
                type="checkbox"
                checked={update.featured}
                onChange={(e) =>
                  setUpdate({ ...update, featured: e.target.checked })
                }
                className="h-5 w-5 rounded border-gray-300 dark:border-gray-600"
              />
              <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                Featured Update
              </span>
            </label>
          </div>
        </div>

        <button
          type="submit"
          className="w-full mt-8 bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
          disabled={uploadingFile}
        >
          {uploadingFile
            ? "Uploading..."
            : id === "new"
            ? "Create Update"
            : "Save Update"}
        </button>
      </form>
    </div>
  );
};

export default UpdateEditor;
