import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="relative mb-16 w-full aspect-[21/9] overflow-hidden">
        <div
          className="absolute inset-0 w-full h-full"
          style={{
            backgroundImage: `url('/images/legal.webp')`,
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>

      <div className="max-w-4xl mx-auto px-4 pb-16">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          Terms and Conditions
        </h1>

        <div className="prose dark:prose-invert max-w-none">
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing and using the AZ Boys Gymnastics website, you accept
            and agree to be bound by the terms and provision of this agreement.
          </p>

          <h2>2. Use License</h2>
          <p>
            Permission is granted to temporarily download one copy of the
            materials (information or software) on AZ Boys Gymnastics's website
            for personal, non-commercial transitory viewing only.
          </p>

          <h2>3. Registration and Events</h2>
          <p>
            Event registrations are subject to availability and confirmation.
            Cancellation policies may apply to specific events and will be
            communicated during registration.
          </p>

          <h2>4. User Conduct</h2>
          <p>
            Users agree to use the website in a manner consistent with all
            applicable laws and regulations.
          </p>

          <h2>5. Content</h2>
          <p>
            All content provided on this website is for informational purposes
            only. AZ Boys Gymnastics makes no representations as to the accuracy
            or completeness of any information on this site.
          </p>

          <h2>6. Modifications</h2>
          <p>
            AZ Boys Gymnastics may revise these terms of service at any time
            without notice. By using this website, you agree to be bound by the
            current version of these terms of service.
          </p>

          <h2>7. Contact Information</h2>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us through our provided contact methods.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
