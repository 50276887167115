const events = [
  {
    id: 6,
    title: "Fun To Flip 2025",
    date: "2025-02-08",
    time: "8:00 AM",
    endTime: "5:00 PM",
    location:
      "Southwest Gymnastics Training Center, Inc.\n4930 S. Ash Avenue, 105\nTempe, AZ 85282",
    category: "Competition",
    description:
      "Annual Fun To Flip gymnastics competition hosted by Southwest Gymnastics Training Center. Features multiple sessions for different levels with custom-designed, silk-screened T-shirts for all participants.",
    registrationDeadline: "2025-01-08",
    price:
      "Level 3: $80.00, Levels 4-6: $90.00, NGA 1-5: $80.00, NGA 6-10: $90.00",
    levelRequired: "Level 3-6 (USAG), NGA 1-10",
    coordinator: "Brock Anstine",
    contactEmail: "Staff@FunToFlip.com",
    contactPhone: "480.730.8911",
    image: "/images/fun-to-flip-2025.jpg",
    dateDisplay: "February 8th, 2025",
    documents: [
      {
        name: "Event Flyer",
        type: "pdf",
        storageRef: "events/2025-fun-to-flip/Fun-To-Flip-2025.pdf",
      },
    ],
    additionalDetails: {
      venue: "Southwest Gymnastics Training Center",
      meetDirector: "Brock Anstine",
      schedule: {
        sessions: [
          { name: "SESSION 1", time: "TBD" },
          { name: "SESSION 2", time: "TBD" },
          { name: "SESSION 3", time: "TBD" },
        ],
      },
      registrationInfo: {
        platform: "USAGym.org meet registration platform",
        ngaProcess: "NGA email in roster",
        mailingAddress: {
          name: "Southwest Gymnastics",
          address: "4930 S. Ash Avenue, 105",
          city: "Tempe",
          state: "AZ",
          zip: "85282",
        },
        contact: {
          phone: "480.730.8911",
          email: "Staff@FunToFlip.com",
        },
      },
      entryFees: {
        level3: {
          price: "$80.00 PER GYMNAST",
          includes: "T-shirt",
        },
        level4_6: {
          price: "$90.00 PER GYMNAST",
          includes: "T-shirt",
        },
        nga1_5: {
          price: "$80 PER GYMNAST",
          includes: "T-shirt",
        },
        nga6_10: {
          price: "$90 PER GYMNAST",
          includes: "T-shirt",
        },
      },
      additionalNotes: [
        "No Team Fees!",
        "Custom-Designed, silk-screened T-shirts included",
        "Schedule is tentative and dependent on Final Numbers",
        "May need to add Sunday based on registration numbers",
        "Late fee of $5.00 per gymnast will apply to all entries postmarked after January 8th, 2025",
        "Entries after final deadline will be accepted based on space availability",
      ],
    },
  },
];

export const categories = [
  "all",
  "Competition",
  "Training",
  "Workshop",
  "Exhibition",
];

export default events;
