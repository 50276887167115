import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../config/firebase";
import { collection, getDocs } from "firebase/firestore";
import Calendar from "./Calendar";

const categories = ["all", "Competition", "Training", "Workshop", "Exhibition"];

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedCategory, setSelectedCategory] = useState("all");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, "events");
        const querySnapshot = await getDocs(eventsCollection);
        const eventsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventsList);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const filteredEvents =
    selectedCategory === "all"
      ? events
      : events.filter((event) => event.category === selectedCategory);

  const handleEventClick = (event) => {
    navigate(`/events/${event.id}`);
  };

  const formatTime = (timeString) => {
    if (!timeString) return "";

    // Parse the time string (assuming format like "14:00")
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));

    return date
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .replace(/\s+/g, "")
      .toLowerCase(); // Remove space between time and AM/PM
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div
        className="relative mb-16"
        style={{
          backgroundImage: "url('/images/heroo.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Add a dark overlay */}
        <div className="absolute inset-0 bg-black/60" />

        <div className="relative max-w-7xl mx-auto px-4 py-24">
          {/* Header */}
          <div className="text-center mb-8">
            <h1 className="text-4xl md:text-5xl font-bold mb-4 text-white">
              Upcoming Events
            </h1>
            <p className="text-lg text-gray-100 max-w-2xl mx-auto">
              Stay up to date with our latest events and activities
            </p>
          </div>

          {/* Category Filter */}
          <div className="flex flex-wrap justify-center gap-3">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-6 py-2.5 rounded-full text-sm font-semibold transition-all
                  ${
                    selectedCategory === category
                      ? "bg-white text-blue-600 shadow-lg"
                      : "bg-white/20 text-white hover:bg-white/30 backdrop-blur-sm"
                  }`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 pb-16">
        {/* Calendar Section */}
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl dark:shadow-gray-900/10 p-6 mb-16 border border-gray-100 dark:border-gray-700/50">
          <Calendar
            events={filteredEvents}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            onEventClick={handleEventClick}
          />
        </div>

        {/* Events List */}
        <div className="grid md:grid-cols-2 gap-8">
          {filteredEvents.length > 0 ? (
            filteredEvents.map((event) => (
              <div
                key={event.id}
                onClick={() => handleEventClick(event)}
                className="group bg-white dark:bg-gray-800 rounded-xl shadow-lg dark:shadow-gray-900/10 p-6 
                  transition-all duration-300 hover:-translate-y-1 hover:shadow-xl cursor-pointer 
                  border border-gray-100 dark:border-gray-700/50 hover:border-blue-500/50 dark:hover:border-blue-500/30"
              >
                <div className="flex justify-between items-start mb-4">
                  <span
                    className="px-4 py-1.5 bg-blue-50 dark:bg-blue-500/10 text-blue-600 dark:text-blue-400 
                    text-sm font-medium rounded-full"
                  >
                    {event.category}
                  </span>
                  <time className="text-sm text-gray-500 dark:text-gray-400">
                    {new Date(event.date).toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </time>
                </div>
                <h3 className="text-xl font-bold mb-3 text-gray-900 dark:text-white group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors">
                  {event.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300 mb-4 line-clamp-2">
                  {event.description}
                </p>
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    {formatTime(event.time)} - {formatTime(event.endTime)}
                  </span>
                  <span
                    className="inline-flex items-center text-blue-600 dark:text-blue-400 font-semibold 
                    group-hover:text-blue-700 dark:group-hover:text-blue-300"
                  >
                    View Details
                    <svg
                      className="w-4 h-4 ml-2 transform transition-transform group-hover:translate-x-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className="col-span-2 text-center py-12">
              <p className="text-gray-500 dark:text-gray-400">
                No events found for this category
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Events;
