import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import events from "../../data/events";

const Chatbot = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      type: "bot",
      content:
        "Hi! I can help you find information about our events and updates. What would you like to know?",
      options: [
        "Upcoming Events",
        "Latest Updates",
        "Competition Schedule",
        "Registration Status",
      ],
    },
  ]);
  const [inputValue, setInputValue] = useState("");
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getUpcomingEvents = () => {
    const today = new Date();
    return events.filter((event) => new Date(event.date) >= today).slice(0, 3);
  };

  const handleSendMessage = async (message) => {
    // Add user message
    setMessages((prev) => [...prev, { type: "user", content: message }]);

    // Simulate bot response
    setTimeout(() => {
      let botResponse;
      switch (message.toLowerCase()) {
        case "upcoming events":
          const upcomingEvents = getUpcomingEvents();
          botResponse = {
            type: "bot",
            content: "Here are our upcoming events:",
            events: upcomingEvents,
            options: [
              "View All Events",
              "Competition Schedule",
              "Register for Event",
              "Back to Menu",
            ],
          };
          break;

        case "latest updates":
          botResponse = {
            type: "bot",
            content: "Here are our most recent updates:",
            updates: [
              {
                title: "New Competition Schedule Released",
                date: "2024-03-15",
              },
              { title: "Summer Camp Registration Open", date: "2024-03-10" },
            ],
            options: ["View All Updates", "Competition Info", "Back to Menu"],
          };
          break;

        case "competition schedule":
          botResponse = {
            type: "bot",
            content: "Our upcoming competitions:",
            competitions: events
              .filter((event) => event.category === "Competition")
              .slice(0, 3),
            options: [
              "Register for Competition",
              "View All Events",
              "Back to Menu",
            ],
          };
          break;

        case "registration status":
          botResponse = {
            type: "bot",
            content: "Which registration would you like to check?",
            options: [
              "Summer Camp",
              "Competition Teams",
              "Regular Classes",
              "Back to Menu",
            ],
          };
          break;

        case "view all events":
          navigate("/events");
          botResponse = {
            type: "bot",
            content:
              "I've opened the events page for you. Is there anything else you'd like to know?",
            options: ["Latest Updates", "Competition Schedule", "Back to Menu"],
          };
          break;

        case "view all updates":
          navigate("/updates");
          botResponse = {
            type: "bot",
            content:
              "I've opened the updates page for you. Can I help you with anything else?",
            options: [
              "Upcoming Events",
              "Competition Schedule",
              "Back to Menu",
            ],
          };
          break;

        case "back to menu":
          botResponse = {
            type: "bot",
            content: "What would you like to know?",
            options: [
              "Upcoming Events",
              "Latest Updates",
              "Competition Schedule",
              "Registration Status",
            ],
          };
          break;

        default:
          botResponse = {
            type: "bot",
            content:
              "I'm not sure about that. Can I help you with one of these topics?",
            options: [
              "Upcoming Events",
              "Latest Updates",
              "Competition Schedule",
              "Registration Status",
            ],
          };
      }
      setMessages((prev) => [...prev, botResponse]);
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      handleSendMessage(inputValue);
      setInputValue("");
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* Chat Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-blue-600 dark:bg-blue-500 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors"
        aria-label={isOpen ? "Close chat" : "Open chat"}
      >
        {isOpen ? (
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
            />
          </svg>
        )}
      </button>

      {/* Chat Window */}
      {isOpen && (
        <div
          className="absolute bottom-16 right-0 w-96 max-w-[calc(100vw-2rem)] bg-white dark:bg-gray-800 rounded-lg shadow-xl flex flex-col border border-gray-200 dark:border-gray-700"
          style={{
            height: "32rem",
            maxHeight: "calc(100vh - 120px)",
            transform: "translateX(0)",
          }}
        >
          {/* Header */}
          <div className="shrink-0 p-4 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
              Event Assistant
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Ask me about events and updates
            </p>
          </div>

          {/* Messages */}
          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${
                  message.type === "user" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`max-w-[80%] rounded-lg p-3 ${
                    message.type === "user"
                      ? "bg-blue-600 text-white"
                      : "bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                  }`}
                >
                  <p>{message.content}</p>

                  {/* Event List */}
                  {message.events && (
                    <div className="mt-2 space-y-2">
                      {message.events.map((event, i) => (
                        <div
                          key={i}
                          className="p-2 bg-white dark:bg-gray-600 rounded-lg"
                        >
                          <p className="font-semibold text-gray-900 dark:text-gray-100">
                            {event.title}
                          </p>
                          <p className="text-sm text-gray-600 dark:text-gray-400">
                            {new Date(event.date).toLocaleDateString()}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Updates List */}
                  {message.updates && (
                    <div className="mt-2 space-y-2">
                      {message.updates.map((update, i) => (
                        <div
                          key={i}
                          className="p-2 bg-white dark:bg-gray-600 rounded-lg"
                        >
                          <p className="font-semibold text-gray-900 dark:text-gray-100">
                            {update.title}
                          </p>
                          <p className="text-sm text-gray-600 dark:text-gray-400">
                            {new Date(update.date).toLocaleDateString()}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Quick Reply Options */}
                  {message.options && (
                    <div className="mt-2 space-y-2">
                      {message.options.map((option) => (
                        <button
                          key={option}
                          onClick={() => handleSendMessage(option)}
                          className="block w-full text-left px-3 py-2 text-sm bg-white dark:bg-gray-600 text-gray-900 dark:text-gray-100 rounded hover:bg-gray-50 dark:hover:bg-gray-500 transition-colors"
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>

          {/* Input area */}
          <div className="shrink-0 p-4 border-t border-gray-200 dark:border-gray-700">
            <form
              onSubmit={handleSubmit}
              className="p-4 border-t border-gray-200 dark:border-gray-700"
            >
              <div className="flex space-x-2">
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Type your message..."
                  className="flex-1 px-4 py-2 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg 
                    text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400
                    focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
                    focus:border-blue-500 dark:focus:border-blue-400"
                />
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 dark:bg-blue-500 text-white rounded-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
